/* General Resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family-base);
  background-color: var(--background-color-website);
  color: var(--text-color);
  line-height: 1.5;
}

/* Header */
.App-header {
  background-color: var(--background-color-primary);
  color: var(--color-brighter);
  padding: var(--padding-medium);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 80px;
  margin-right: var(--margin-medium);
}

/* Navigation */
nav {
  background-color: var(--background-color-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-small);
}

.left-nav, .right-nav {
  display: flex;
  align-items: center;
}

.platform-label, .assistant-label {
  font-weight: bold;
  font-size: var(--font-size-normal);
  color: var(--text-color-secondary);
  margin-right: var(--margin-medium);
}

input[type="text"] {
  padding: var(--padding-small);
  border: var(--border-standard);
  border-radius: var(--border-radius);
  background-color: var(--background-color-primary);
  color: var(--text-color-secondary);
  min-width: 350px;
}

input[type="text"]:read-only {
  background-color: var(--background-color-disable);
  color: var(--text-color-placeholder);
}

/* Main Content */
main {
  padding: var(--padding-medium);
}

/* Error Message */
.error-message {
  color: var(--color-messages-error);
  margin: var(--margin-medium) 0;
  text-align: center;
}

/* Footer */
footer {
  background-color: var(--color-footer);
  color: var(--color-brighter);
  padding: var(--padding-small);
  text-align: center;
}

/* Utility Classes */
.flag-bar {
  height: 5px;
  background-color: var(--accent-color);
}
