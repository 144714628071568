.login-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background-transparency);
    z-index: 1000;
}

.login-popup-content {
    background: var(--background-color-primary);
    padding: var(--padding-large);
    border-radius: var(--border-radius-medium);
    box-shadow: var(--box-shadow);
    text-align: center;
}

.close-button {
    float: right;
    border: none;
    background: none;
    color: var(--text-color);
    font-size: var(--font-size-large);
    cursor: pointer;
}

.form-group {
    margin-bottom: var(--margin-medium);
}

.form-group label {
    display: block;
    margin-bottom: var(--margin-small);
    color: var(--text-color);
}

.form-group input {
    width: 100%;
    padding: var(--padding-small);
    border: var(--border-standard);
    border-radius: var(--border-radius-small);
    color: var(--text-color);
    background-color: var(--background-color-secondary);
}

.login-button {
    width: 100%;
    padding: var(--padding-medium);
    border: none;
    border-radius: var(--border-radius-small);
    background-color: var(--accent-color);
    color: var(--color-brighter);
    cursor: pointer;
    transition: var(--transition-colors);
}

.login-button:hover {
    background-color: var(--color-hover-lighter);
}
