.rubro-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Adjust the number 5 if there are more or fewer rubrics */
    gap: var(--gap-medium);
    padding: var(--padding-medium);
    background-color: var(--background-color-secondary);
    color: var(--text-color);
    border-radius: var(--border-radius-medium);
    margin-bottom: var(--margin-large);
    overflow-x: auto; /* Ensures the content is scrollable horizontally on smaller screens */
}

.rubro-grid-cell {
    display: flex;
    flex-direction: column;
    padding: var(--padding-small);
}

.rubro-title {
    font-size: var(--font-size-large);
    color: var(--text-color-secondary);
    margin-bottom: var(--margin-small);
}

input[type="radio"] {
    margin-right: var(--margin-small);
}

label {
    cursor: pointer;
    transition: var(--transition-colors); /* Smooth transition for background and color changes */

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

/* Styling for checked radio buttons */
input[type="radio"]:checked + label {
    background-color: var(--background-color-hover);
    border-radius: var(--border-radius-small);
    padding: var(--padding-small);
    box-shadow: var(--box-shadow-hover);
    transition: var(--transition-colors); /* Add transition for smooth effect */
}

/* Hover effect for labels */
label:hover {
    background-color: var(--background-color-focus);
    box-shadow: var(--box-shadow-focus); /* Shadow effect without moving the label */
    /* Padding and border-radius are kept the same to avoid movement */
}

