/* LandingPage.css */
.LandingPage {
    font-family: var(--font-family-base);
    color: var(--color-brighter);
    background-color: var(--background-color-website);
    height: 100vh;
    display: flex;
    flex-direction: column;
}


.LandingPage-header {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.LandingPage-header h1 {
    font-size: 3rem;
    margin-bottom: var(--margin-small);
}

.LandingPage-header p {
    font-size: var(--font-size-normal);
    margin-bottom: var(--margin-medium);
}

.LandingPage-footer {
    background-color: var(--color-footer);
    padding: var(--padding-small);
    text-align: center;
}

.unselectable {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

/* Ensure the rest of your CSS is consistent with the color scheme and sizes */
