.criterio-container {
    background-color: var(--background-color-secondary);
    padding: var(--padding-medium);
    margin-bottom: var(--margin-large);
    border-radius: var(--border-radius-medium);
    box-shadow: var(--box-shadow);
}

.criterio-title {
    color: var(--text-color);
    margin-bottom: var(--margin-medium);
}

.rubros-container {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
}