.evaluation-result {
    color: var(--text-color-secondary);
    text-align: left;
    background-color: var(--background-color-website);
    padding: var(--padding-medium);
    border-radius: var(--border-radius-medium);
    box-shadow: var(--box-shadow);
}


.evaluation-result p,
.evaluation-result ul,
.evaluation-result ol,
.evaluation-result h1,
.evaluation-result h2,
.evaluation-result blockquote {
    /* your styles here */
    color: var(--text-color); /* example */
    padding: var(--padding-small);
}

.retroalimentacion h2 {
    color: var(--text-color);
    margin-bottom: var(--margin-medium);
}



/* Responsive typography */
@media (max-width: 768px) {
    .retroalimentacion h2 {
        font-size: var(--font-size-large);
    }
}

/* Spinner/Loader styles */
.spinner {
    border: 4px solid var(--background-color-secondary);
    border-top: 4px solid var(--accent-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
