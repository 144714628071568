.common-button {
    padding: var(--padding-small);
    background-color: var(--background-color-secondary);
    color: var(--color-brighter);
    border: none;
    border-radius: var(--border-radius-small);
    cursor: pointer;
    transition: var(--transition-colors);
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.common-button.primary {
    background-color: var(--accent-color);
}

.common-button:hover,
.common-button:focus {
    background-color: var(--color-hover-lighter);
    outline: none;
    box-shadow: var(--box-shadow-focus);
}

.common-button:active {
    box-shadow: var(--box-shadow-active);
}

.common-button:disabled {
    background-color: var(--background-color-disable);
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .common-button {
        font-size: var(--font-size-normal);
    }
}

.common-button .spinner {
    border: 2px solid var(--background-color-secondary); /* Lighter spinner border */
    border-top: 2px solid var(--color-brighter); /* Visible part of the spinner */
    border-radius: 50%;
    width: 24px; /* Spinner size */
    height: 24px;
    animation: spin 1s linear infinite;
}

/* Ensure spinner is only visible when loading */
.common-button:not(:disabled) .spinner {
    display: none;
}

.common-button:disabled .spinner {
    display: inline-block;
}

/* Existing spinner keyframes */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive button font size */
@media (max-width: 768px) {
    .common-button {
        font-size: var(--font-size-normal);
    }
}