.module-info {
    margin: 20px;
    padding: 10px;
    border: 1px solid var(--border-color);
    background-color: var(--background-color-secondary);
    border-radius: var(--border-radius-medium);
}

.module-info h2 {
    color: var(--text-color);
    margin-bottom: var(--margin-medium);
}

.datagrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gap-medium);
}

.grid-cell {
    display: flex;
    flex-direction: column;
}

.grid-cell label {
    font-size: var(--font-size-normal);
    color: var(--text-color);
    margin-bottom: var(--margin-small);
}

.grid-cell input[type="text"], .grid-cell select {
    padding: var(--padding-small);
    border: var(--border-standard);
    border-radius: var(--border-radius);
    background-color: var(--background-color-primary);
    color: var(--text-color-secondary);
    transition: var(--transition-colors);
}

.grid-cell input[type="text"]:focus, .grid-cell select:focus {
    outline: none;
    border-color: var(--accent-color);
    box-shadow: var(--box-shadow-focus);
}

.grid-cell input[type="text"]:read-only {
    background-color: var(--background-color-disable);
    color: var(--text-color-placeholder);
}
