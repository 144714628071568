.message-banner {
    position: fixed;
    top: var(--message-banner-top-position);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--message-banner-padding-vertical) var(--message-banner-padding-horizontal);
    border-radius: var(--message-banner-border-radius);
    color: var(--message-banner-color);
    animation: slideDown var(--message-animation-duration) ease-in-out;
    box-shadow: var(--message-banner-box-shadow);
    z-index: var(--message-banner-z-index);
    font-size: var(--message-banner-font-size);
    font-weight: var(--message-banner-font-weight);
}

.message-icon {
    display: inline-flex;
    margin-right: var(--message-icon-margin-right);
}

.message-error {
    background-color: var(--message-error-bg);
}

.message-warning {
    background-color: var(--message-warning-bg);
}

.message-success {
    background-color: var(--message-success-bg);
}

.message-info {
    background-color: var(--message-info-bg);
}

@keyframes slideDown {
    from {
        top: 0;
        opacity: 0;
    }
    to {
        top: var(--message-banner-top-position);
        opacity: 1;
    }
}